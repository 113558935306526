import React, { useState, useEffect } from "react";
import {
  CartContainer,
  Table,
  TableRow,
  TableSeparatorDown,
  Td,
  // CartEmpty,
  // TitleEmpty,
  // BoxEmpty,
  Button,
} from "./styles";
import configs from "config";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";


const MyOrders = (props) => {
  const url = `${configs.api}user-order/${props.user_id}`;
  const [orderUser, setOrderUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const ordersPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const handleRowClick = (order) => {
    navigate(`/order/${order.slt_id}`);
  };
  
  useEffect(() => {
    const fetchOrderData = async () => {
      const statusOrder = "Iniciado";
      try {
        const token = Cookies.get("authToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(url, { headers, params: {statusOrder, tipoVenda : "site"}});
        const dados = response.data;
        setOrderUser(dados.data);

        setTotalPages(Math.ceil(dados.data.length / ordersPerPage));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [url, ordersPerPage]);

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orderUser.slice(indexOfFirstOrder, indexOfLastOrder);

  const renderTable = () => {
    return (
      <>
        <Table>
          <thead>
            <TableRow>
              <th>CODIGO DO PEDIDO</th>
              <th>FORMA DE PAGAMENTO</th>
              <th>STATUS PAGAMENTO</th>
              <TableSeparatorDown />
            </TableRow>
          </thead>
          <tbody>
            {currentOrders.map((order, i) => (
              <TableRow key={order.slt_id} onClick={() => handleRowClick(order)} style={{ cursor: "pointer" }}>
                <Td>{order.slt_id}</Td>
                <Td>
                  {order.campo_custom_valor.map((forma_pagamento) =>
                    forma_pagamento.cfd_id === 761
                      ? forma_pagamento.cfv_externalvalue
                      : ""
                  )}
                </Td>
                <Td style={{ width: "200px", color: getStatusColor(order.slt_status) }}>
                  {order.slt_status}
                </Td>
              </TableRow>
            ))}


          </tbody>
        </Table>
        {totalPages > 1 && (
          <>

            {currentPage !== 1 && (

              <Button
                style={{ width: "20%" }}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Anterior
              </Button>
            )}
            {currentPage < totalPages && (
              <Button
                style={{ width: "20%" }}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Próxima
              </Button>
            )}

          </>
        )}
      </>
    );
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Pagamento Reprovado":
        return "#FF5555";
      default:
        return "#000000";
    }
  };
  return (
    <div style={{ minHeight: "700px"}}>
      {isLoading ? (

          <Loader style={{ minHeight: "700px"}} />
      ) : (
        <CartContainer>{renderTable()}</CartContainer>
      )}
    </div>
  );
};

export default MyOrders;
