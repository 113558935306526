import {
  ContainerText,
  Header,
  HeaderTitle,
  Text,
  CardContainer,
  Card,
  Title,
  Description,
  NumberCircle,
  ButtonDiv,
  ImageContainer,
  Image,
  BackgroundImage,
  TextDiv,
  ButtonSt,
  Content,
} from "./styles";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import Button from "components/Button";
import { Link } from "react-router-dom";
import useImageProgressive from "hooks/useImageProgressive";
import { useTranslation } from "react-i18next"; // Importa o hook de tradução

const GiveMe = () => {
  const [image1] = useImageProgressive(
    "assets/imgs/downgrade/model_orange.jpg",
    "assets/imgs/model_orange.jpg"
  );
  const { t } = useTranslation(); // Usa o hook de tradução

  return (
    <Layout>
      <Content border>
        <ImageContainer border>
          <Image borderRight>
            <BackgroundImage image={image1} />
          </Image>
          <TextDiv>
            <Header>
              <HeaderTitle>GIVE ME</HeaderTitle>
            </Header>
            <Text>{t("newWayToDetach")}</Text>
            <Button>
              <Link
                to="https://wa.me/message/APZHBOCKQT5SE1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000" }}
                as="div"
              >
                {t("scheduleAppointment")}
              </Link>
            </Button>
          </TextDiv>
        </ImageContainer>
        <ContainerText>
          <Header>
            <HeaderTitle>{t("knowGiveMe")}</HeaderTitle>
          </Header>
          <Text>{t("newWayToDetach")}</Text>
          <CardContainer>
            <Card>
              <NumberCircle>01</NumberCircle>
              <Title>{t("newPaths")}</Title>
              <Description>{t("weTakeCareOfEverything")}</Description>
            </Card>
            <Card>
              <NumberCircle>02</NumberCircle>
              <Title>{t("detachAndCuratorship")}</Title>
              <Description>{t("newPurpose")}</Description>
            </Card>
            <Card>
              <NumberCircle>03</NumberCircle>
              <Title>{t("leaveWithUs")}</Title>
              <Description>{t("deliverClothes")}</Description>
            </Card>
          </CardContainer>

          <Text style={{ marginTop: "8%" }}>{t("donationSeal")}</Text>

          <ButtonDiv>
            <Link
              to="https://wa.me/message/APZHBOCKQT5SE1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#000" }}
              as="div"
            >
              <ButtonSt>{t("wantToSell")}</ButtonSt>
            </Link>
          </ButtonDiv>
        </ContainerText>
      </Content>
      <Follow />
    </Layout>
  );
};

export default GiveMe;
