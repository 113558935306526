import ButtonMenu from "components/ButtonMenu";
import {
  CartItemCount,
  Container,
  Content,
  FlexMenu,
  Header,
  ItemMenu,
  ItemMenuNav,
  ItemSubMenu,
  ItemSubMenuBag,
  ModalLoginBarContent,
  ModalLoginBarWrapper,
  ModalCartBarContent,
  ModalCartBarWrapper,
  SearchBar,
  ItemSubMenuLogin,
  ItemSubMenuSearch,
  ButtonSpanHeader
} from "./styles";
import logo from "assets/imgs/logo.png";
import TextSpan from "components/TextSpan";
// import { BiSearch } from "react-icons/bi";
import { BsBag, BsPerson, BsSearch } from "react-icons/bs";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import Menu from "../Menu";
import { brands } from "../brands";
import { shops } from "../shops";
import { useNavigate, useLocation } from "react-router-dom";
import Search from "components/Search";
import LoginModal from "../../../components/LoginModal";
import CartModal from "components/CartModal";
import ModalMobile from "components/ModalMobile";
import { CartContext } from "context/CartContext";
import AppContext, { actions } from "context/app";
import { CiMenuBurger } from "react-icons/ci";
import { useAuth } from "context/AuthContext";
import useImageProgressive from "hooks/useImageProgressive";
// import LanguageSwitcher from "components/LanguageSwitch";

const App = forwardRef(({ children, colorMenu }, ref) => {
  const [brandBack] = useImageProgressive("assets/imgs/downgrade/bolsa_pb.jpg", "assets/imgs/bolsa_pb.jpg");
  const [shopsBack] = useImageProgressive("assets/imgs/downgrade/bolsa_gudes.jpg", "assets/imgs/bolsa_gudes.jpg");
  const { state, dispatch } = useContext(AppContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [isModalCartOpen, setIsModalCartOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalMobileOpen, setIsModalMobileOpen] = useState(false);
  const [modalMenuOpen, setModalMenuOpen] = useState("shop");
  const [filtro, setFiltro] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { cartItems } = useContext(CartContext);
  const cartItemCount = cartItems.length;

  const isLoggedIn = useAuth().userName;
  const navigate = useNavigate();
  const lists = {
    brands: brands,
    shop: shops,
  };

  const handleFilterChange = (newFilter) => {
    if (newFilter !== "" && newFilter !== filtro) {
      setFiltro(newFilter);
      handleMenuClose();
    }
  };

  const backgroundMenu = {
    brands: brandBack,
    shop: shopsBack,
  };
  const handleMenuOpen = (menu) => {
    dispatch({ type: actions.HANDLE_MENU, payload: menu });
  };

  const handleMenuClose = () => {
    dispatch({ type: actions.HANDLE_MENU, payload: null });

    // setTimeout(() => {
    //   //
    // },1000)
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  const location = useLocation();

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleModalLogin = () => {
    setIsModalLoginOpen(!isModalLoginOpen);

    if (state.menuOpen === "conta") {
      dispatch({ type: actions.HANDLE_MENU, payload: null });
    } else if (isLoggedIn !== "" && isLoggedIn !== undefined) {
      setIsModalLoginOpen(false);
      navigate({
        pathname: "/conta",
        search: `pedido=true`,
      });
    } else {
      setIsModalCartOpen(false);
      dispatch({ type: actions.HANDLE_MENU, payload: "conta" });
    }
  };

  const handleModalLoginClose = () => {
    setIsModalLoginOpen(false);
  };

  const toggleModalCart = () => {
    setIsModalCartOpen(!isModalCartOpen);
  };

  const handleModalCartClose = () => {
    setIsModalCartOpen(false);
  };

  const toggleMobileMenu = () => {
    setModalMenuOpen(modalMenuOpen === "shop" ? "brands" : "shop");
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsModalMobileOpen(!isModalMobileOpen);
  };

  const closeModalMobile = () => {
    setIsModalMobileOpen(false);
    setIsMobileMenuOpen(false);
  };

  const modalLoginRef = useRef();
  const modalCartRef = useRef(); // Add a new ref for the cart modal

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the login modal
      if (
        modalLoginRef.current &&
        !modalLoginRef.current.contains(event.target)
      ) {
        handleModalLoginClose();
      }

      // Check if the click is outside the cart modal
      if (
        modalCartRef.current &&
        !modalCartRef.current.contains(event.target)
      ) {
        handleModalCartClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalLoginRef, modalCartRef]);

  useEffect(() => {
    if (isModalLoginOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isModalLoginOpen]);

  return (
    <Container ref={ref}>
      <Header onMouseLeave={handleMenuClose}>
        <FlexMenu>
          <ItemMenu onClick={() => navigate("/")}>
            <ButtonMenu active={location.pathname === "/"} color={colorMenu} border>
              <ButtonSpanHeader  >TAKE ME VINTAGE </ButtonSpanHeader>
            </ButtonMenu>
          </ItemMenu>
          <ItemMenu onClick={() => navigate("/around")}>
            <ButtonMenu active={location.pathname === "/around"} color={colorMenu} border>
              <ButtonSpanHeader>TAKE ME AROUND </ButtonSpanHeader>
            </ButtonMenu>
          </ItemMenu>
          <ItemMenu onClick={() => navigate("/selection")}>
            <ButtonMenu active={location.pathname === "/selection"} color={colorMenu} border>
              <ButtonSpanHeader>TAKE ME SELECTION </ButtonSpanHeader>
            </ButtonMenu>
          </ItemMenu>
        </FlexMenu>
        <div>

          <FlexMenu height={"50px"} color={colorMenu} border>

            {windowWidth < 1025 && (
              <div style={{ marginLeft: window.innerWidth < 1068 ? "10px" : "40px", display: "flex" }}>
                <CiMenuBurger onClick={toggleMobileMenu}>Menu</CiMenuBurger>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <ItemMenuNav>
                <FlexMenu height={"50%"} color={colorMenu}>
                  <ItemSubMenu
                    onMouseOver={() => handleMenuOpen("novidades")}
                    onClick={() => navigate("/novidades")}
                  >
                    <TextSpan
                      fontWeight={state.menuOpen === "novidades" ? 500 : 300}
                    >
                      New in
                    </TextSpan>
                  </ItemSubMenu>
                  <ItemSubMenu
                    onMouseOver={() => handleMenuOpen("shop")}
                    onClick={() => navigate("/shop")}
                  >
                    <TextSpan fontWeight={state.menuOpen === "shop" ? 500 : 300}>
                      Shop
                    </TextSpan>
                  </ItemSubMenu>

                  <ItemSubMenu onMouseOver={() => handleMenuOpen("brands")}
                    onClick={() => navigate("/brandlist")}>
                    <TextSpan
                      fontWeight={state.menuOpen === "brands" ? 500 : 300}
                    >
                      Marcas
                    </TextSpan>
                  </ItemSubMenu>
                  <ItemSubMenuSearch
                    onMouseOver={() => handleMenuOpen("search")}
                    style={{ position: "relative" }}
                  >
                    <BsSearch size={24} onClick={toggleSearch} />

                  </ItemSubMenuSearch>
                  {/* <ItemSubMenu
                    onMouseOver={() => handleMenuOpen("sale")}
                    onClick={() => navigate("/sale")}
                  >
                    <TextSpan fontWeight={state.menuOpen === "sale" ? 500 : 300}>
                      Sale
                    </TextSpan>
                  </ItemSubMenu> */}
                  {/* <ItemSubMenu onMouseOver={() => handleMenuOpen("shop")}>
                  <TextSpan fontWeight={menuOpen === "shop" ? 500 : 300}>
                    Shop
                  </TextSpan>
                </ItemSubMenu> */}
                  {/* <ItemSubMenu onClick={toggleSearch}>
                  <BiSearch size={24} />
                </ItemSubMenu> */}
                </FlexMenu>
              </ItemMenuNav>
              <ItemMenu
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              >
                <img
                  alt="logo takeme"
                  src={logo}
                  width={window.innerWidth < 768 ? "120px" : "250px;"}
                />
              </ItemMenu>
              <ItemMenuNav>

                <FlexMenu color={colorMenu}>
                  <ItemSubMenu onClick={() => navigate("/sellwithus")}>
                    <TextSpan
                      fontWeight={state.menuOpen === "sellwithus" ? 500 : 300}
                    >
                      <TextSpan>Sell With Us</TextSpan>
                    </TextSpan>
                  </ItemSubMenu>
                  <ItemSubMenu onClick={() => navigate("/giveme")}>
                    <TextSpan
                      fontWeight={state.menuOpen === "giveme" ? 500 : 300}
                    >
                      <TextSpan>Give Me</TextSpan>
                    </TextSpan>
                  </ItemSubMenu>
                  <ItemSubMenuLogin onClick={toggleModalLogin}>
                    <BsPerson size={24} />
                  </ItemSubMenuLogin>
                  {/* <ItemSubMenu>
                    <BsStar size={24} />
                  </ItemSubMenu> */}
                  <ItemSubMenuBag
                    style={{ position: "relative" }}
                    onClick={toggleModalCart}
                  >
                    <BsBag size={24} />
                    {cartItemCount > 0 && (
                      <CartItemCount>{cartItemCount}</CartItemCount>
                    )}
                  </ItemSubMenuBag>
                  {/* <LanguageSwitcher /> */}
                </FlexMenu>
              </ItemMenuNav>
            </div>
          </FlexMenu>
          <Menu
            items={lists[state.menuOpen]}
            open={state.menuOpen}
            background={backgroundMenu[state.menuOpen]}
            searchOpen={isSearchOpen}
            type={state.menuOpen === "shop" ? "shop" : null}
            modalLoginOpen={isModalLoginOpen}
            modalCartOpen={isModalCartOpen}
            onFilterChange={handleFilterChange}
          />
        </div>
      </Header>
      <Content>
        {children}
        {isSearchOpen && (
          <SearchBar>
            <Search onSearch={handleSearchClose} onClose={toggleSearch} />
          </SearchBar>
        )}
        {isModalLoginOpen && (
          <ModalLoginBarWrapper isOpen={isModalLoginOpen}>
            <ModalLoginBarContent isOpen={isModalLoginOpen} ref={modalLoginRef}>
              <LoginModal
                onLogin={handleModalLoginClose}
                onClose={toggleModalLogin}
              />
            </ModalLoginBarContent>
          </ModalLoginBarWrapper>
        )}
        {isModalCartOpen && (
          <ModalCartBarWrapper isOpen={isModalCartOpen}>
            <ModalCartBarContent isOpen={isModalCartOpen} ref={modalCartRef}>
              <CartModal
                onLogin={handleModalCartClose}
                onClose={toggleModalCart}
              />
            </ModalCartBarContent>
          </ModalCartBarWrapper>
        )}
        {isModalMobileOpen && (
          <ModalMobile
            isOpen={isModalMobileOpen}
            onClose={closeModalMobile}
          ></ModalMobile>
        )}
      </Content>
    </Container>
  );
});

export default App;
