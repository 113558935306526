import {
  ContainerText,
  Header,
  HeaderTitle,
  Text,
  CardContainer,
  Card,
  Title,
  Description,
  NumberCircle,
  IconCardContainer,
  IconCard,
  Icon,
  ButtonDiv,
  ImageContainer,
  Image,
  BackgroundImage,
  TextDiv,
  ButtonSt,
  IconTitle,
  Content,
} from "./styles";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import Icon1 from "../../assets/imgs/icon1.png";
import Icon2 from "../../assets/imgs/icon2.png";
import Button from "components/Button";
import { Link } from "react-router-dom";
import useImageProgressive from "hooks/useImageProgressive";
import { useTranslation } from "react-i18next";
const SellWithUs = () => {
  const [image1] = useImageProgressive(
    "assets/imgs/downgrade/model_walk_edited.jpg",
    "assets/imgs/model_walk_edited.jpg"
  );
  const { t } = useTranslation();

  return (
    <Layout>
      <Content border>
        <ImageContainer border>
          <Image borderRight>
            <BackgroundImage image={image1} />
          </Image>
          <TextDiv>
            <Header>
              <HeaderTitle>Sell With Us</HeaderTitle>
            </Header>
            <Text>{t("findNewPaths")}</Text>
            <Button>
              <Link
                to="https://wa.me/message/APZHBOCKQT5SE1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000" }}
                as="div" // Renderiza o Link como uma div
              >
                {t("scheduleAppointment")}
              </Link>
            </Button>
          </TextDiv>
        </ImageContainer>
        <ContainerText>
          <Header>
            <HeaderTitle>{t("whySellWithUs")}</HeaderTitle>
          </Header>
          <Text>{t("weTakeCareOfEverything")}</Text>

          <CardContainer>
            <Card>
              <NumberCircle>01</NumberCircle>
              <Title>{t("itIsTrustworthy")}</Title>
              <Description>{t("weTakeCareOfEverything")}</Description>
            </Card>
            <Card>
              <NumberCircle>02</NumberCircle>
              <Title>{t("itIsSustainable")}</Title>
              <Description>{t("newPurposeForLooks")}</Description>
            </Card>
            <Card>
              <NumberCircle>03</NumberCircle>
              <Title>{t("itIsDiscreet")}</Title>
              <Description>{t("personalizedService")}</Description>
            </Card>
          </CardContainer>

          <Header>
            <HeaderTitle>{t("sellWithTakeMe")}</HeaderTitle>
          </Header>
          <Text>{t("exclusiveBenefits")}</Text>

          <IconCardContainer border>
            <IconCard>
              <Icon src={Icon1} alt="Ícone 1" style={{ width: "25%" }} />
              <IconTitle>{t("benefit01")}</IconTitle>
              <Description>{t("directTransfer")}</Description>
            </IconCard>
            <IconCard>
              <Icon src={Icon2} alt="Ícone 2" />
              <IconTitle>{t("benefit02")}</IconTitle>
              <Description>{t("balanceWithBonus")}</Description>
            </IconCard>
          </IconCardContainer>

          <ButtonDiv>
            <Link
              to="https://wa.me/message/APZHBOCKQT5SE1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#000" }}
              as="div" // Renderiza o Link como uma div
            >
              <ButtonSt>{t("iWantToSellWithTakeMe")}</ButtonSt>
            </Link>
          </ButtonDiv>
        </ContainerText>
      </Content>
      <Follow />
    </Layout>
  );
};

export default SellWithUs;
