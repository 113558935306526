import React from "react";
import { Button, Title, CenteredDiv } from "./styles";
import barCode from "assets/imgs/barCode.png";
import { useNavigate } from "react-router-dom";

const PagamentoBoleto = ({ dados, tipoEntrega, idPedido }) => {
  const navigate = useNavigate();
  const mensagem = `Olá,\nMeu pedido é o ${idPedido}\nVou retirar meu pedido na loja\nGostaria de agendar uma data e hora para essa retirada!`;
  const textoFormatado = encodeURIComponent(mensagem);
  let links = null;
  let email = null;
  if (
    dados &&
    dados.charges[0].payment_method.boleto &&
    dados.charges.length > 0
  ) {
    links = dados.charges[0].links[0];
    email = dados.customer.email;
  }

  const truncatedEmail = email
    ? `${email.substring(0, 2)}***@${email.split("@")[1]}`
    : "";
  return (
    <CenteredDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Title>Seu pedido foi realizado com sucesso</Title>
        <p style={{ fontSize: "14px" }}>Boleto gerado, acesse o link abaixo:</p>
        <p style={{ fontSize: "14px", textAlign: "center" }}>
          O boleto foi enviado, também, para o e-mail {truncatedEmail}
        </p>
        <a href={links.href} target="_blank" rel="noopener noreferrer">
          <img src={barCode} alt="Clique para visualizar o boleto bancário" />
        </a>
        <p style={{ fontSize: "14px" }}>
          Clique na imagem para visualizar o boleto bancário
        </p>

        {tipoEntrega === "retirado_loja" && (
          <>
            <p style={{ fontSize: "14px" }}>
              Para retirar o seu pedido em nossa loja, por favor, clique no
              botão abaixo para agendar uma data e hora de retirada
            </p>

            <Button
               onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=+5511999900100&text=${textoFormatado}`,
                  "_blank"
                )
              }
            >
              Agendar Retirada
            </Button>
          </>
        )}
         <p
          style={{ fontSize: "14px", cursor:"pointer" }}
          onClick={() => navigate("/conta?pedido=true")}
        >
          Ir para "Meus Pedidos"
        </p>
      </div>
    </CenteredDiv>
  );
};

export default PagamentoBoleto;
