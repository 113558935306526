import React from "react";
import { Button, Title, CenteredDiv } from "./styles";
import { useNavigate } from "react-router-dom";
import { BsCheckCircleFill, BsClockFill, BsLockFill } from "react-icons/bs";

const PagamentoCartao = ({ dados, tipoEntrega, idPedido }) => {
  const navigate = useNavigate();
  let description = null;
  let status = null;
  let message = null;
  let statusColor = "var(--Marrom, #c0b6ad)";
  let Icon = null;
  const mensagem = `Olá,\nMeu pedido é o ${idPedido}\nVou retirar meu pedido na loja\nGostaria de agendar uma data e hora para essa retirada!`;
  const textoFormatado = encodeURIComponent(mensagem);
  if (dados) {
    description = dados.charges[0].description ?? "";
    message = dados.charges[0].payment_response.message ?? "";
    status = dados.charges[0].status ?? "";

    if (status.toLowerCase() === "paid") {
      statusColor = "green";
      Icon = BsCheckCircleFill;
    } else if (status.toLowerCase() === "declined") {
      statusColor = "red";
      Icon = BsLockFill;
    } else {
      Icon = BsClockFill;
    }
  }

  return (
    <CenteredDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Title>Seu pedido foi realizado com sucesso!</Title>
        <p style={{ fontSize: "14px" }}>Acompanhe a situação do seu pedido</p>

        {tipoEntrega === "retirado_loja" && (
          <>
            <p style={{ fontSize: "14px" }}>
              Para retirar o seu pedido em nossa loja, por favor, clique no
              botão abaixo para agendar uma data e hora de retirada
            </p>

            <Button
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=+5511999900100&text=${textoFormatado}`,
                  "_blank"
                )
              }
            >
              Agendar Retirada
            </Button>
          </>
        )}

        <p
          style={{ fontSize: "14px", cursor:"pointer"  }}
          onClick={() => navigate("/conta?pedido=true")}
        >
          Ir para "Meus Pedidos"
        </p>
        <p style={{ padding: "0", marginBottom: "0" }}>{description}</p>
        <p style={{ color: statusColor, marginLeft: "12px" }}>
          <Icon /> {message}
        </p>
      </div>
    </CenteredDiv>
  );
};

export default PagamentoCartao;
