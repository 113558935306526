import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  ModalContent,
  Span,
  SpanForgot,
  Text,
  InputCadastro,
  ImgLogo,
  ButtonEye,
  // InputDate,
  ButtonEyeLogin,
  Content,
  ButtonEye2
} from "./styles";
import axios from "axios";
import InputMask from "react-input-mask";
import { useAuth } from "context/AuthContext";
import { DivInputs, Button2 } from "./styles";
import configs from "config";
import logo_footer from "assets/imgs/logo-footer.png";
import logo from "assets/imgs/logo.png";
import { BsCalendarDate, BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
// import ModalAviso from "components/ModalAviso";
import Loader2 from "components/Loader2";
import { CloseButton } from "components/ModalMobile/styles";

const LoginModal = ({ onLogin }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const { login, setUserName, setId } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isColor, setColor] = useState("");

  // const [modalAvisoVisible, setModalAvisoVisible] = useState(false);
  // const [modalAvisoMensagem, setModalAvisoMensagem] = useState("");

  const [textAviso, setTextAviso] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const resetTextAviso = () => {
    setTextAviso("");
  };

  useEffect(() => {
    const timeoutId = setTimeout(resetTextAviso, 5000);

    return () => clearTimeout(timeoutId);
  }, [textAviso]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmSenha: "",
    cnpj_cpf: "",
    cpf: "",
    cnpj: "",
    cellphone: "",
    fantasyName: "",
    birthdate: "",
    active: "true",
    type: "true",
  });
  const [addressData, setAddressData] = useState({
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    uf: "",
  });

  const handleUsernameChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (email.toLowerCase().endsWith(".online")) {
      return true;
    }

    return emailRegex.test(email);
  };
  const handleClose = () => {
    onLogin(false);
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      const lowerCaseValue = value.toLowerCase();
      if (!isEmailValid(lowerCaseValue)) {
      }
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: lowerCaseValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddressData((prevAddressData) => ({
      ...prevAddressData,
      [name]: value,
    }));
  };
  const arePasswordsEqual = () => {
    return formData.password === formData.confirmSenha;
  };

  const isValidCPF = (cpf) => {
    if (!cpf || cpf.length !== 14) {
      return false;
    }

    const sanitizedCPF = cpf.replace(/[^\d]/g, "");
    if (
      sanitizedCPF === "00000000000" ||
      sanitizedCPF === "11111111111" ||
      sanitizedCPF === "22222222222" ||
      sanitizedCPF === "33333333333" ||
      sanitizedCPF === "44444444444" ||
      sanitizedCPF === "55555555555" ||
      sanitizedCPF === "66666666666" ||
      sanitizedCPF === "77777777777" ||
      sanitizedCPF === "88888888888" ||
      sanitizedCPF === "99999999999"
    ) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(sanitizedCPF.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(sanitizedCPF.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum += parseInt(sanitizedCPF.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(sanitizedCPF.substring(10, 11))) {
      return false;
    }

    return true;
  };
  const isValidCEP = (cep) => {
    const cepRegex = /^\d{8}$/;
    return cepRegex.test(cep);
  };


  const handleNextClick = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    if (currentStep === 1) {
      if (
        formData.name &&
        formData.email &&
        formData.password &&
        formData.confirmSenha &&
        arePasswordsEqual() &&
        (isPessoaFisica
          ? formData.cpf
          : formData.fantasyName && formData.cnpj)
      ) {
        formData.cnpj_cpf = formData.cpf
          ? formData.cpf.replace(/[-_.]/g, "")
          : formData.cnpj.replace(/[-_.]/g, "");
        try {
          const dados = {
            ...formData,
          };

          const response = await axios.post(configs.api + "search_params", dados);
          

          if(formData.cpf !== ""){
            if (!isValidCPF(formData.cpf)) {
              setColor("red");
              setTextAviso("CPF inválido. Verifique o formato.");
              setIsLoading(false);
              return false;
            }
          }
         
      
          if (response.status === 200) {
            setCurrentStep(2);
            setIsLoading(false);

          }
        } catch (error) {
          setTextAviso(error.response.data.message);
          console.error(
            error.response.data.message
          );
          setIsLoading(false);
        }
      } else {
        if (!arePasswordsEqual()) {
          setTextAviso("As senhas não correspondem. Por favor, verifique.");
          setIsLoading(false);

        } else {
          setTextAviso("Por favor, preencha todos os campos obrigatórios.");
          setIsLoading(false);

        }
      }
    } else if (currentStep === 2) {
      if (
        addressData.zipCode &&
        addressData.street &&
        addressData.number &&
        addressData.neighborhood &&
        addressData.city &&
        addressData.uf
      ) {
        handleSubmit();
      } else {
        setTextAviso("Por favor, preencha todos os campos obrigatórios.");
        setIsLoading(false);

      }
    }
  };

  const [isPessoaFisica, setIsPessoaFisica] = useState(true);

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };
  const buscarEndereco = async () => {
    const cep = document.getElementById("cep").value.replace(/\D/g, "");
    setTextAviso("Buscando...");

    if (cep.length !== 8) {
      setTextAviso("CEP inválido. Certifique-se de que o CEP contém 8 dígitos.");
      setIsLoading(false);

      return;
    }
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (response.data.erro) {
        setTextAviso("CEP não encontrado. Verifique o CEP digitado.");
        setIsLoading(false);

        return;
      }
      const { logradouro, bairro, localidade, uf, complemento } = response.data;

      setAddressData({
        ...addressData,
        zipCode: cep,
        street: logradouro,
        neighborhood: bairro,
        city: localidade,
        uf: uf,
        complement: complemento,
      });

    setTextAviso("");

    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };
  const handleCreateUser = () => {
    setCreateUser(true);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(configs.api + "login", {
        email,
        password,
      });

      if (response.status === 200) {
        const data = response.data;

        login(data.token, data.name, data.id);
        setUserName(data.name);
        setId(data.id);
        setIsLoading(false);
        setTextAviso("Logado com sucesso");
        onLogin(false);
        setIsLoading(false);


      } else {
        setTextAviso("Credenciais inválidas");
        setIsLoading(false);

        onLogin(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Erro na chamada da API de login", error);
      setIsLoading(false);
      setTextAviso("Erro ao fazer login. Por favor, tente novamente.");
    }
  };
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const handleRegister = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    formData.cnpj_cpf = formData.cpf
      ? formData.cpf.replace(/[-_.]/g, "")
      : formData.cnpj.replace(/[-_.]/g, "");
    try {
      const dados = {
        ...formData,
        ...addressData,
        origin: window.location.origin,
      };
      if (!isValidCEP(addressData.zipCode)) {
        setTextAviso( "CEP inválido. Certifique-se de que o CEP contém 8 dígitos.");
        setIsLoading(false);
        return false;
      }
      const response = await axios.post(configs.api + "client_site", dados);


      if (response.status === 200) {
        setRegistrationSuccess(true);
        setCreateUser(false);
        setIsLoading(false);
        setFormData({
          name: "",
          email: "",
          password: "",
          confirmSenha: "",
          cnpj_cpf: "",
          cpf: "",
          cnpj: "",
          cellphone: "",
          fantasyName: "",
          birthdate: "",
          active: "true",
          type: "true",
        });

        setAddressData({
          zipCode: "",
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          uf: "",
        });
      } else {
        setTextAviso("Não foi possivel cadastrar o usuário!");
        setIsLoading(false);
        onLogin(false);

      }
    } catch (error) {
      setTextAviso(error.response.data.message);
      console.error(
        "Erro na chamada da API de login",
        error.response.data.message
      );
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await axios.post(configs.api + "password/send", {
        email,
        origin: window.location.origin,
      });
      setColor("green");
      setTextAviso("Se o e-mail estiver registrado em nosso sistema, você receberá um e-mail de redefinição de senha");
    } catch (error) {
      setColor("green");

      setTextAviso("Se o e-mail estiver registrado em nosso sistema, você receberá um e-mail de redefinição de senha");

    }
  };
  // const closeModalAviso = () => {
  //   setModalAvisoVisible(false);
  // };

  return (
    <>
      {/* {modalAvisoVisible ? (
        <ModalAviso
          visible={modalAvisoVisible}
          mensagem={modalAvisoMensagem}
          onClose={closeModalAviso}
          showOverlay={false}
        />
      ) : ( */}
      <Modal>
      <CloseButton onClick={handleClose}>X</CloseButton>
        <ImgLogo>
          <img width={50} src={logo_footer} alt="Logo Takeme"></img>
          <img width={window.innerWidth > 768 ? 250 : 200} src={logo} alt="Takeme"></img>
        </ImgLogo>
        <Content>

          {forgotPassword ? (
            <Form onSubmit={handlePasswordReset}>
              <div >
                <Span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setForgotPassword(false)}
                >
                  Voltar
                </Span>
                <Text>Esqueceu a senha?</Text>

              </div>
              <div>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleUsernameChange}
                  placeholder="E-mail"
                />
              </div>

              {textAviso.length > 0 && (
                <div style={{ color: isColor, fontSize: "14px", textAlign: "center" }}>
                  <span style={{ color: isColor, fontSize: "14px", textAlign: "center" }}>{textAviso}</span>

                </div>
              )}
              <Button style={{ marginTop: "10px", width: "102.3%" }} type="submit">Enviar</Button>
            </Form>
          ) : createUser ? (
            <ModalContent>
              {currentStep === 1 ? (
                <div style={{ position: "relative", width: "100%" }}>
                  <DivInputs>
                    <div>
                      <Span
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => setCreateUser(false)}
                      >
                        Voltar
                      </Span>
                      <Text>Dados Pessoais</Text>
                    </div>
                    <div style={{ position: 'relative', width: "100%" }}>
                      <InputCadastro
                        type="text"
                        placeholder="Nome"
                        name="name"
                        value={formData.name}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div style={{ position: 'relative', width: "100%" }}>
                      <InputMask
                        mask="99/99/9999"
                        alwaysShowMask={false}
                        formatChars={{ 9: "[0-9]" }}
                        placeholder="Data de Nascimento"
                        name="birthdate"
                        value={formData.birthdate}
                        onChange={handleFormChange}
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#f7f4ec",
                          borderRadius: "25px",
                          border: "1px solid #000",
                          width: "100%",
                          fontFamily: "Thasadith",
                          fontStyle: "normal",
                        }}
                      />
                          <ButtonEye
                        style={{
                          position: 'absolute',
                          top: '53%',
                          right: '-6px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                       <BsCalendarDate /> 
                      </ButtonEye>
                    </div>
                    <div style={{ position: 'relative', width: "100%" }}>
                      <InputCadastro
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleFormChange}
                      />
                      
                    </div>
                    <div style={{ position: 'relative', width: "100%" }}>
                      <InputCadastro
                        type={showPassword ? "text" : "password"}
                        placeholder="Senha"
                        name="password"
                        value={formData.password}
                        onChange={handleFormChange}
                        id="password"
                      />
                      <ButtonEye
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          top: '53%',
                          right: '-6px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        {showPassword ? <BsEyeFill /> : <BsFillEyeSlashFill />}
                      </ButtonEye>
                    </div>
                    <div style={{ position: 'relative', width: "100%" }}>
                      <InputCadastro
                        type={showPasswordConfirm ? "text" : "password"}
                        placeholder="Confirmar Senha"
                        name="confirmSenha"
                        value={formData.confirmSenha}
                        onChange={handleFormChange}
                      />
                      <ButtonEye2
                        style={{
                          position: 'absolute',
                          top: '53%',
                          right: '-6px',
                          transform: 'translateY(-50%)',
                        }}
                        onClick={togglePasswordConfirmVisibility}
                      >
                        {showPasswordConfirm ? <BsEyeFill /> : <BsFillEyeSlashFill />}
                      </ButtonEye2>
                    </div>
                    <div style={{ position: 'relative', width: "100%" }}>
                      <InputMask
                        mask="(99) 99999-9999"
                        maskChar="_"
                        alwaysShowMask={false}
                        formatChars={{ 9: "[0-9]" }}
                        placeholder="Telefone"
                        name="cellphone"
                        value={formData.cellphone}
                        onChange={handleFormChange}
                        style={{
                          fontSize: "14px",
                          border: "1px solid #000",
                          backgroundColor: "#f7f4ec",
                          borderRadius: "25px",
                          width: "100%",
                        }}
                      />
                    </div>
                    <div style={{ width: "100%", display: "flex", }}>
                      <ButtonLabel
                        isActive={isPessoaFisica}
                        onClick={() => setIsPessoaFisica(true)}
                      >
                        Pessoa Física
                      </ButtonLabel>
                      <ButtonLabel
                        isActive={!isPessoaFisica}
                        onClick={() => setIsPessoaFisica(false)}
                      >
                        Pessoa Jurídica
                      </ButtonLabel>
                    </div>

                    {isPessoaFisica ? (
                      <div style={{ width: "100%", position: 'relative' }}>
                        <InputMask
                          mask="999.999.999-99"
                          maskChar="_"
                          alwaysShowMask={false}
                          formatChars={{ 9: "[0-9]" }}
                          placeholder="CPF"
                          name="cpf"
                          value={formData.cpf}
                          onChange={handleFormChange}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #000",
                            backgroundColor: "#f7f4ec",
                            borderRadius: "25px",
                            width: '100%',
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "100%", height: "80px", position: 'relative' }}>
                        <InputCadastro
                          type="text"
                          placeholder="Nome Fantasia"
                          name="fantasyName"
                          value={formData.fantasyName}
                          onChange={handleFormChange}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #000",
                            backgroundColor: "#f7f4ec",
                            borderRadius: "25px",
                            width: '100%',
                          }}
                        />
                        <InputMask
                          mask="99.999.999/9999-99"
                          maskChar="_"
                          alwaysShowMask={false}
                          formatChars={{ 9: "[0-9]" }}
                          placeholder="CNPJ"
                          name="cnpj"
                          value={formData.cnpj}
                          onChange={handleFormChange}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #000",
                            backgroundColor: "#f7f4ec",
                            borderRadius: "25px",
                            marginTop: "10px",
                            width: '100%',

                          }}
                        />
                      </div>
                    )}
                    {textAviso.length > 0 && (
                      <span style={{ color: "red", textAlign: "center", fontSize: "14px" }}>{textAviso}</span>
                    )}
                  </DivInputs>
                </div>

              ) : (
                <DivInputs style={{ flexDirection: windowWidth > 768 ? "column" : "" }}>
                  <div>
                    <Span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setCurrentStep(1)}
                    >
                      Voltar
                    </Span>
                    <Text>Endereço</Text>
                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>

                    <InputMask
                      id="cep"
                      placeholder="00000-000"
                      mask="99999-999"
                      maskChar="_"
                      alwaysShowMask={false}
                      formatChars={{ 9: "[0-9]" }}
                      className="col-6"
                      name="zipCode"
                      value={addressData.zipCode}
                      onChange={handleAddressChange}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "#f7f4ec",
                        borderRadius: "25px",
                        width: "45%"
                      }}
                    />
                    <Button2 style={{
                      width: "48%"
                    }} onClick={buscarEndereco}>Buscar Endereço</Button2>
                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                    <input
                      id="logradouro"
                      type="text"
                      className="input2"
                      placeholder="Logradouro"
                      name="street"
                      disabled={true}
                      value={addressData.street}
                      onChange={handleAddressChange}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "rgb(221, 221, 221)",
                        borderRadius: "25px",
                        width: windowWidth < 768 ? "43%" : "45%",
                      }}
                    />
                    <InputMask
                      mask="999999"
                      maskChar=" "
                      alwaysShowMask={false}
                      formatChars={{ 9: "[0-9]" }}
                      vclassName="input1"
                      placeholder="Número"
                      name="number"
                      value={addressData.number}
                      onChange={handleAddressChange}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "#f7f4ec",
                        borderRadius: "25px",
                        width: windowWidth < 768 ? "43%" : "45%",
                      }}
                    />
                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                  <input
                      id="bairro"
                      type="text"
                      className="input1"
                      placeholder="Bairro"
                      name="neighborhood"
                      disabled={true}
                      value={addressData.neighborhood}
                      onChange={handleAddressChange}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "rgb(221, 221, 221)",
                        borderRadius: "25px",
                        width: windowWidth < 768 ? "43%" : "45%",
                      }}
                    />
                    <input
                      id="complemento"
                      type="text"
                      className="input2"
                      placeholder="Complemento"
                      name="complement"
                      value={addressData.complement}
                      onChange={handleAddressChange}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "#f7f4ec",
                        borderRadius: "25px",

                        width: windowWidth < 768 ? "43%" : "45%",
                      }}
                    />
                  
                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                    <input
                      id="cidade"
                      type="text"
                      className="input1"
                      placeholder="Cidade"
                      name="city"
                      value={addressData.city}
                      disabled={true}
                      onChange={handleAddressChange}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "rgb(221, 221, 221)",
                        borderRadius: "25px",
                        width: windowWidth < 768 ? "100%" : "45%",
                      }}
                    />
                    <input
                      id="estado"
                      type="text"
                      className="input1"
                      placeholder="Estado"
                      name="uf"
                      value={addressData.uf}
                      onChange={handleAddressChange}
                      disabled={true}
                      style={{
                        marginLeft: windowWidth < 768 ? "0" : "6px",
                        fontSize: "14px",
                        border: "1px solid #000",
                        backgroundColor: "rgb(221, 221, 221)",
                        borderRadius: "25px",
                        width: windowWidth < 768 ? "100%" : "45%",
                      }}
                    />
                  </div>
                  {textAviso.length > 0 && (
                <div style={{ color: isColor, fontSize: "14px", textAlign: "center" }}>
                  <span style={{ color: isColor, fontSize: "14px", textAlign: "center" }}>{textAviso}</span>

                </div>
              )}
                </DivInputs>
                
              )}
              <DivInputs>
                {isLoading ? (
                  <div style={{ margin: "auto" }}>
                    <Loader2 />
                  </div>
                ) : (
                  <div style={{ width: "100%", height: "80px", position: 'relative' }}>
                    <Button style={{ marginTop: "10px" }}
                      onClick={currentStep === 1 ? handleNextClick : handleRegister}
                      disabled={isLoading}
                    >
                      {currentStep === 1 ? "Próximo" : "Cadastrar"}
                    </Button>
                  </div>

                )}
              </DivInputs>
            </ModalContent>
          ) : registrationSuccess ? (
            <div>
              <p style={{fontSize:"14px"}}>Enviamos um E-mail de confirmação de conta.</p>
              <Button onClick={() => setRegistrationSuccess(false)}>Fechar</Button>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Text style={{ textAlign: "center" }}>Entrar</Text>
              <div style={{ position: "relative", width: "100%" }}>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleUsernameChange}
                  placeholder="E-mail"
                />
                <div style={{ position: 'relative' }}>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Senha"
                  />
                  <ButtonEyeLogin
                    onClick={togglePasswordVisibility}
                    style={{
                      position: 'absolute',
                      top: '42%',
                      right: '0',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {showPassword ? <BsEyeFill /> : <BsFillEyeSlashFill />}
                  </ButtonEyeLogin>
                </div>
              </div>

              <SpanForgot onClick={handleForgotPassword}>
                Esqueceu a senha?
              </SpanForgot>

              {isLoading ? (
                <div style={{ margin: "auto" }}>
                  <Loader2 />
                </div>
              ) : (
                <Button style={{ marginTop: "10px", width: "102.3%" }} type="submit">
                  Entrar
                </Button>
              )}
              {textAviso.length > 0 && (
                <span style={{ color: "red", textAlign: "center", fontSize: "14px" }}>{textAviso}</span>
              )}
              <Span onClick={handleCreateUser}>
                Não tem uma senha? <b style={{ fontWeight: 700, cursor: "pointer" }}> Cadastre agora</b>
              </Span>
            </Form>
          )}
        </Content>
      </Modal>

    </>

  );

};
const ButtonLabel = ({ isActive, onClick, children }) => {
  return (
    <div style={{ display: "flex", width: "100%", marginLeft: "10px" }}>
      <button
        style={{
          backgroundColor: isActive ? "black" : "gray",
          color: "white",
          border: "none",
          cursor: "pointer",
          borderRadius: "24px",
          width: "100%",
          height: "35px",
        }}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};
export default LoginModal;
