import React from "react";
import { Button, Title, CenteredDiv } from "./styles";
import { useNavigate } from "react-router-dom";

const PagamentoPresencial = ({ dados, tipoEntrega, idPedido }) => {
  const navigate = useNavigate();
  console.log("eu", dados);
  console.log("tipoEntrega", tipoEntrega);
  const mensagem = `Olá,\nMeu pedido é o ${idPedido}\nVou retirar meu pedido na loja\nGostaria de agendar uma data e hora para essa retirada!`;
  const textoFormatado = encodeURIComponent(mensagem);
  return (
    <CenteredDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Title>Seu pedido foi realizado com sucesso!</Title>
        {tipoEntrega === "retirado_loja" && (
          <>
            <p style={{ fontSize: "14px" }}>
              Para retirar o seu pedido em nossa loja, por favor, clique no
              botão abaixo para agendar uma data e hora de retirada
            </p>

            <Button
               onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=+5511999900100&text=${textoFormatado}`,
                  "_blank"
                )
              }
            >
              Agendar Retirada
            </Button>
          </>
        )}

        <p
          style={{ fontSize: "14px", cursor:"pointer" }}
          onClick={() => navigate("/conta?pedido=true")}
        >
          Ir para "Meus Pedidos"
        </p>
      </div>
    </CenteredDiv>
  );
};

export default PagamentoPresencial;
